<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap ete_2022">

      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>hébergement</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">Le rabais de 15 % s’applique sur les séjours de 2 nuits</li>
          <li class="regular-text">Le rabais de 25 % s’applique sur les séjours de 3 nuits ou plus.</li>
          <li class="regular-text">Offre valable tous les jours du 1er mai au 31 octobre 2022, sauf du 24 au 26 juin et du 18 au 21 août 2022 où les partenaires hôteliers participants pourraient ne pas offrir de rabais, ou offrir un rabais inférieur à ceux stipulés dans cette offre.</li>
          <li class="regular-text">Offre disponible chez les partenaires hôteliers participants.</li>
          <li class="regular-text">Sujet à disponibilité.</li>
        </ul>
        
        <h2 class="small-title">Achat et réservation</h2>
        <ul>
          <li class="regular-text">L’offre s’applique sur toute réservation effectuée entre le 1er avril et le 14 juin 2022, pour un séjour de 2 nuits ou plus entre le 1er mai et le 31 octobre 2022.</li>
          <li class="regular-text">Le séjour doit être réservé au moins 24 heures d’avance.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Les politiques d’acompte et d’annulation régulières ou les Politiques de réservation et d’annulation flexibles des partenaires s’appliquent à cette offre.</li>
          <li class="regular-text">Les politiques varient d’un partenaire à l’autre.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Du 24 au 26 juin et du 18 au 21 août 2022, les partenaires hôteliers participants pourraient ne pas offrir de rabais, ou offrir un rabais inférieur à ceux stipulés dans cette offre.</li>
          <li class="regular-text">Cette offre ne peut être jumelée avec d’autres offres ou promotions sur l’hébergement.</li>
          <li class="regular-text">Si le séjour chevauche la période de début ou de fin de validité de l’offre, le rabais sera appliqué sur les nuits à l’intérieur de la période de validité selon le rabais correspondant au nombre total de nuits du séjour.</li>
          <li class="regular-text">Ces rabais et dates sont modifiables sans préavis.</li>
        </ul>

      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Lodging<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">The 15% discount applies to 2-night stays</li>
          <li class="regular-text">The 25% discount applies to 3-night stays or more.</li>
          <li class="regular-text">Valid every day from May 1 and October 31, 2022, except from June 24 to 26 and from August 18 to 21, 2022, when participating hotels may offer lower discounts than the ones stipulated in this offer, or no discount at all.</li>
          <li class="regular-text">Offer available at participating hotels.</li>
          <li class="regular-text">Subject to availability.</li>
        </ul>
        
        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Offer applies to bookings made between April 1 and June 14, 2022, on 2-night stays or more from May 1 to October 31, 2022.</li>
          <li class="regular-text">Bookings must be made 24 hours in advance.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Participant lodging partners’ regular deposit and cancellation policies or Flexible reservation and cancellation policies apply to this package. </li>
          <li class="regular-text">Policies may vary from one lodging partner to the other.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">From June 24 to 26 and from August 18 to 21, 2022, participating hotels may offer lower discounts than the ones stipulated in this offer, or no discount at all.</li>
          <li class="regular-text">This offer cannot be combined with any other offer or promotion on lodging.</li>
          <li class="regular-text">If stay period starts or ends outside of the validity period, discount will be applied on nights included within the validity period only and its value will vary according to the number of nights included in the entire stay.</li>
          <li class="regular-text">Discounts and dates are subject to change without notice.</li>
        </ul>

      </div>
      
    </section>

    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"></a>
    </div>

    <div class="overlay-form-loding ete_2022" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
